html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ivory;
}

.ui{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
}
.astronaut-hint > div{
    font-size: 0.9rem;
    color: #a0bd11;
    white-space: nowrap;
    /*width: 250px;*/
    background: rgba(255,255,255, 0.6);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px;
    border-radius: 16px;
    line-height: 0;
    font-family: "D-DIN";
}

